import './Message.css'
import React, { useEffect, useRef, useState } from 'react'
import Axios from "../../Axios";
import StackABI from '../../Web3/stakeAbi.json'
import stakeAddress from '../../Web3/stakeAddress'
import approveABI from '../../Web3/approveAbi.json'
import approveAddress from '../../Web3/approveAddress'
import Web3 from 'web3';
import Box from '@mui/material/Box';
import { io } from "socket.io-client";
import consts from "../../Constant";
import { ToastContainer, toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Container from '@mui/material/Container'
import { useTheme } from '@mui/material/styles';
import { Button } from "@mui/material";
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SendIcon from '@mui/icons-material/Send';
import artist1 from '../../img/dster-webp/artist1.webp'
import artist2 from '../../img/dster-webp/artist2.webp'
import artist3 from '../../img/dster-webp/artist3.webp'
import artist4 from '../../img/dster-webp/artist4.webp'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import HeaderNew from '../HeaderNew/HeaderNew';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const label = { inputProps: { 'aria-label': 'Switch demo' } };


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}






const drawerWidth = 280;


function Message() {
    const params = useParams()
    const navigate = useNavigate()
    let socket = io.connect(consts.SocketUrl);
    const [btnColor, setBtnColor] = React.useState('1')
    const [messageList, setmessageList] = useState([])
    // const [value, setValue] = React.useState(0);
    const [name, setName] = React.useState("0");
    const requestAcc = useRef(true)
    const [metaAccount, setMetaAccount] = useState()
    const [balance, setBalance] = useState()

    const [msg, setMsg] = useState(false)
    const [msg2, setMsg2] = useState(false)

    const [names, setNames] = useState()
    const [ids, setIds] = useState()

    const [roomdetails, setRoomdetails] = useState({})
    const [room, setRoom] = useState()
    const handleMsg = () => { setMsg(true) }
    const handleMsg0 = () => { setMsg2(true) }


    const [audiosource, setAudiosource] = React.useState();
    const [count, setCount] = React.useState()
    const tokens = localStorage.getItem("iphephile");
    const location = useLocation()

    const [audioDurations, setAudioDurations] = useState([]);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // console.log(obj,'library');


    const [display, setDisplay] = useState(true)

    useEffect(() => {

        socket.on("receive", (data) => {
            setmessageList((prev) => [...prev, data]);
        });

    }, []);
    useEffect(() => {
        if (requestAcc.current) {

            if (!metaAccount && tokens) {
                requestAccount()
            }
        }


        requestAcc.current = false


    }, [])


    useEffect(() => {
        getRoom()
    }, [])
    const getRoom = async () => {
        try {
            var findData = {
                receiver_id: params._id,
                requestor1: localStorage.getItem("usrId")
            }


            const datas = await Axios.post("/message/get_one_room", findData, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    console.log(res?.data?.result, "resi")
                    if (res?.data?.success) {
                        if (res?.data?.result !== "") {
                            setMsg2(true)
                            setRoomdetails(res?.data?.result)
                            setRoom(res?.data?.result?.room_id)
                            getMessage(res?.data?.result?.room_id)
                            console.log(res?.data?.result?.room_id, "room")
                            socket.emit("join", {
                                room: res?.data?.result?.room_id,
                            })
                            setNames(res?.data?.result?.creator_name)
                            setIds(localStorage.getItem("usrId"))
                        }
                        else {
                            // setMsg2(false)
                            createRoom()
                        }

                    }
                    else {
                        //toast.error(err?.response?.data?.message)
                        setRoomdetails({})


                    }
                }).catch((err) => {
                    // console.log(err,"err")

                })

        } catch (error) {
            // console.log(error,"error");
        }

    }

    const getMessage = async (data) => {
        try {
            var findData = {
                room_id: data,

            }


            const datas = await Axios.post("/message/get_message", findData, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    console.log(res?.data?.result, "resi")
                    if (res?.data?.success) {
                        handleMsg()
                        setmessageList(res?.data?.result)
                        console.log(data, "room")
                        socket.emit("join", {
                            room: data,
                        })

                    }
                    else {
                        console.log(res?.data)
                        //toast.error(err?.response?.data?.message)
                        // setRoomdetails({})


                    }
                }).catch((err) => {
                    // console.log(err,"err")

                })

        } catch (error) {
            // console.log(error,"error");
        }

    }

    const createMessage = async () => {
        console.log("socket")

        try {
            console.log(room, "room")
            socket.emit("join", {
                room: room,
            })
            const time = `${Date.now()}`
            socket.emit("send", {
                sender_name: names,
                room: room,
                time: time,
                message: updated,
            });

            const { data } = await Axios.post(`/message/create_message`, {

                room: room,
                time: time,
                message: updated,
            }, {
                headers: {

                    Authorization: tokens
                }
            })
            if (data?.success === true) {
                console.log(data?.result)
                // getOneTicketMesg(room)
                getMessage(room)
                setUpdated('')


            }
            else {
                toast.error(data?.message)
            }
        } catch (error) {
            console.log("🚀 ~ file: Header.js:370 ~ getActivity ~ error:", error)
        }


    }

    // const reset = () => {
    //     setstatus("All")
    //     setName()

    // }

    const createRoom = async () => {

        try {
            const amounts = location.state.amount
            const accounts = location.state.account
            const dtokens = await tranferToken(amounts, accounts)
            console.log(typeof (name), "dtokens")
            // if (name == "0") {
            //     toast.error("Error while transferring the token")

            // }
            // else{

            // const { data } = await Axios.post(`/followers/create_room`, {
            //     type: "one",
            //     receiver_id: params?._id,
            //     description: "Description"
            // }, {
            //     headers: {
            //         Authorization: tokens,
            //     },
            // })
            // if (data?.success === true) {
            //     getRoom()
            //     console.log(data, "message")
            //     // toast.success(data?.message)


            // }
            // else {
            //     toast.error(data?.message)
            // }

            // }
        } catch (error) {
            console.log("error:", error)
        }


    }


    const [anchorEl2, setAnchorEl2] = useState(null);

    const handleplaylist = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const open2 = Boolean(anchorEl2);
    const id2 = open2 ? 'simple-popover' : undefined;



    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    //     setName()
    // };

    const [profileData, setProfileData] = useState({});




    const theme = useTheme();

    const [message, setMessage] = useState([]);

    const [updated, setUpdated] = useState('');



    const handleChangeSend = (event) => {
        setUpdated(event.target.value);

    };

    const handleClickSend = (e) => {
        // 👇 "message" stores input field value

        if (updated?.trim() !== '') {
            setMessage([...message, updated])
            setUpdated('')
        }
    };

    const handleDeleteTodo = (index) => {
        const newTodos = [...message];
        newTodos.splice(index, 1);
        setMessage(newTodos);
    };

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            createMessage();
        }
    };

    // substring



    const [chat, setChat] = useState([
        { img: `${artist1}`, name: 'Sankar', noftify: 'Hi da How are you doing long time no see' },
        { img: `${artist2}`, name: 'Barath', noftify: 'Sankar Eod Podra' },
        { img: `${artist3}`, name: 'Priya', noftify: 'Saami Ac ah konjam kammi pannuda' },
        { img: `${artist4}`, name: 'Gowtham', noftify: 'da figma unnaku send panniten' },
        { img: `${artist1}`, name: 'Lucky', noftify: 'Bharath kumar! inga vaa!!' },
        { img: `${artist3}`, name: 'Vignesh', noftify: 'Hi da How are you doing long time no see' },
        { img: `${artist4}`, name: 'Ramesh', noftify: 'Bro Dreamster Merge pannitingala' },
        { img: `${artist2}`, name: 'Arun', noftify: 'Hi da How are you doing long time no see' },
    ])

    const onChangeNetwork = async (chainIds, amount, account) => {
        try {
            const WEB = new Web3(window.ethereum);

            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: WEB.utils.toHex(chainIds) }]
            });
            tranferToken(amount, account)
            //navigateToBack()

        } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
                var data = {
                    "name": "BNB Smart Chain Mainnet",
                    "chain": "BSC",
                    "rpc": [
                        "https://bsc-dataseed1.bnbchain.org",
                        "https://bsc-dataseed2.bnbchain.org",
                        "https://bsc-dataseed3.bnbchain.org",
                        "https://bsc-dataseed4.bnbchain.org",
                        "https://bsc-dataseed1.defibit.io",
                        "https://bsc-dataseed2.defibit.io",
                        "https://bsc-dataseed3.defibit.io",
                        "https://bsc-dataseed4.defibit.io",
                        "https://bsc-dataseed1.ninicoin.io",
                        "https://bsc-dataseed2.ninicoin.io",
                        "https://bsc-dataseed3.ninicoin.io",
                        "https://bsc-dataseed4.ninicoin.io",
                        "https://bsc.publicnode.com",
                        "wss://bsc.publicnode.com",
                        "wss://bsc-ws-node.nariox.org"
                    ],
                    "faucets": [],
                    "nativeCurrency": {
                        "name": "BNB Chain Native Token",
                        "symbol": "BNB",
                        "decimals": 18
                    },
                    "infoURL": "https://www.bnbchain.org/en",
                    "shortName": "bnb",
                    "chainId": 56,
                    "networkId": 56,
                    "slip44": 714,
                    "explorers": [
                        {
                            "name": "bscscan",
                            "url": "https://bscscan.com",
                            "standard": "EIP3091"
                        }
                    ]
                }


                // console.log(chainLists1, "chainlist")
                const WEB = new Web3(window.ethereum);
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: data?.chain,
                            chainId: WEB.utils.toHex(chainIds),
                            nativeCurrency: data?.nativeCurrency,
                            rpcUrls: [data?.rpc[0]]
                        }
                    ]
                });
            }
            else if (err.code === 4001) {
                // navigate(0)
                console.log(err)
            }
            else {
                console.log(err)
            }
        }
    }

    const tranferToken = async (amounts, account) => {
        try {
            if (amounts == undefined) {
                toast.error("amount is required")


            }
            else if (account == undefined) {
                toast.error("Wallet Address is Unavailable")
            }

            else if (window.ethereum) {

                const web3Instance = new Web3(window.ethereum);

                const browserChainId = await web3Instance.eth.getChainId()


                await window.ethereum.enable();
                // setWeb3(web3Instance);

                await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                const accounts = await web3Instance.eth.getAccounts();
                console.log(amounts, "accont", account, Number(browserChainId) === Number(consts.chainId))

                if (Number(browserChainId) === Number(consts.chainId)) {
                    const contractInstance = new web3Instance.eth.Contract(
                        approveABI,
                        approveAddress
                        // consts.Abi,
                        // consts.Address,

                    );
                    const Transfer = await contractInstance.methods.transfer(
                        String(account),
                        web3Instance.utils.toWei(String(amounts), 'ether')

                    ).send({ from: accounts[0] }).then(async (response) => {
                        setName("1")
                        const { data } = await Axios.post(`/followers/create_room`, {
                            type: "one",
                            receiver_id: params?._id,
                            description: "Description"
                        }, {
                            headers: {
                                Authorization: tokens,
                            },
                        })
                        if (data?.success === true) {
                            getRoom()
                            console.log(data, "message")
                            // toast.success(data?.message)


                        }
                        else {
                            toast.error(data?.message)
                        }

                    }).catch((err) => {
                        console.log(err)
                        setName("0")
                        toast.error("Error while transferring the token")
                    })



                    //  return bal
                }
                else {
                    onChangeNetwork(consts.chainId, amounts, account)
                }


            }
        } catch (err) {
            return false

        }
    };
    const getToken = async () => {
        try {
            if (window.ethereum) {
                const web3Instance = new Web3(window.ethereum);

                await window.ethereum.enable();
                // setWeb3(web3Instance);

                await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                const accounts = await web3Instance.eth.getAccounts();

                const contractInstance = new web3Instance.eth.Contract(
                    StackABI,
                    stakeAddress
                );

                const Token = await contractInstance.methods.token().call()

                const tokenInstance = new web3Instance.eth.Contract(
                    approveABI,
                    approveAddress //token address and abi to check token balance
                );
                const balances = await tokenInstance.methods.balanceOf(accounts[0]).call()
                const bal = await web3Instance.utils.fromWei(String(balances), 'ether')
                setBalance(Number(bal))


            }
        } catch (err) {
            console.log(err)
        }
    };



    const requestAccount = async () => {

        if (window.ethereum) {

            try {
                const accounts = await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                setMetaAccount(accounts[0])
                getToken()
                localStorage.setItem('accounts', accounts[0])

            } catch (error) {
                console.log(error, 'Error connecting...');
            }

        } else {
            alert('Please Add Metamask Extension in Your Browser');
        }
    }

    //personal chat

    const [perChat, setPerChat] = useState([])


    //search

    const [search, setSearch] = useState()
    const [imageUrl, setImageUrl] = useState(null);
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        console.log(reader, "result of file");

        reader.onloadend = () => {
            setImageUrl(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };


    const drawer = (
        <div>
            <Grid container spacing={0}>

                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>

                    <div className="library-head-div">

                    </div>
                    {display ? (<>
                        <div className="colab-msg-box">
                            <div className="colab-avatar-cont vr">
                                <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                                    <ArrowBackIosOutlinedIcon onClick={(() => { navigate(-1) })} sx={{ fill: '#000' }} />
                                    <Avatar sx={{ width: 35, height: 35 }} alt="Remy Sharp" src={String(roomdetails?.requestor1) === ids ? roomdetails?.receiverDetails?.profilePicture : roomdetails?.requestorDetails?.profilePicture} />
                                    <div>
                                        {String(roomdetails?.requestor1) === ids ? roomdetails?.receiverDetails?.name : roomdetails?.requestorDetails?.name}
                                    </div>

                                </Stack>
                            </div>
                            <div>


                                <div className="colab-chat-area msg2">

                                    {messageList.map((todo, ind) => {
                                        return (

                                            <div className="chat-area-inner-text user" key={ind}>
                                                <div>
                                                    {/* <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={artist3} /> */}
                                                </div>
                                                <div className={(String(todo?.from?._id) === ids) ? "collab-send-text-main2 user" : "collab-send-text-main"}>
                                                    <div className="username">{(String(todo?.from?._id) === ids) ? "You" : <>{todo?.to?.name}</>}</div>
                                                    <div className="txt1">{todo?.message}</div>

                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                            <div className="colab-text-input">

                                <div>
                                    <input type="text" placeholder="Start typing..."
                                        value={updated}
                                        onChange={handleChangeSend}
                                        onKeyDown={handleEnterKeyPress}

                                    />
                                </div>
                                <div className="colab-send">
                                    <SendIcon onClick={() => { createMessage() }} />
                                </div>
                            </div>
                        </div>
                    </>) :
                        (<>

                            <div className='msglist'>
                                <div className='msg-list-head'>
                                    <div className='chats'>
                                        Chats
                                    </div>
                                    {/* <div className='msg-lsit-head-svg'>
                                        <MoreHorizIcon />
                                        <SwitchVideoIcon />
                                        <ModeEditOutlinedIcon />
                                    </div> */}
                                </div>
                                <div className='msg-list-input'>
                                    {/* <SearchOutlinedIcon />  <input type='search' placeholder='Search Messenger' /> */}

                                    <FormControl fullWidth sx={{ m: 0.5 }}>
                                        {/* <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel> */}
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            startAdornment={<InputAdornment position="start"><SearchOutlinedIcon /></InputAdornment>}
                                            label="Amount"
                                            className='msg-input'
                                            placeholder='Search Messenger'
                                            onChange={((e) => { setSearch(e.target.value) })}
                                        // value={search}
                                        />
                                    </FormControl>

                                </div>
                                <div className='msg-list-area'>
                                    {chat.filter((obj) => {
                                        return (
                                            search?.toLocaleLowerCase() === undefined ? obj : obj.name.toLocaleLowerCase().includes(search)
                                        )

                                    }).map((row, ind) => {
                                        return (
                                            <div key={ind} className='msg-list-chat-main' onClick={(() => { setPerChat(row); setDisplay(true) })}>
                                                <div>
                                                    <Avatar sx={{ width: 56, height: 56 }} alt="Remy Sharp" src={row.img} />
                                                </div>
                                                <div className='msg-list-chat-name'>
                                                    {row.name}
                                                    <div className='chat-desc' id='sentence'>
                                                        {(row.noftify?.length > 30) ? row.noftify.substring(0, 28) + '...' : row.noftify}
                                                        <div>4w</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </>)

                    }

                </Grid>
            </Grid>
        </div>
    )
    return (
        <div className="message">
            <ToastContainer

                autoClose={3000}
                position="top-right"

            />
            <Box sx={{ display: 'flex' }}>
                <HeaderNew title={"Message"} />


                <Box component="main"
                    className="library-box"
                    sx={{
                        flexGrow: 1, py: 1,

                        '@media (max-width:991.68px)': {
                            width: (`calc(100% - ${drawerWidth}px)`)
                        },

                        marginTop: '55px'

                    }}>
                    <Grid container spacing={0} className="heading-container">
                        <Container maxWidth="xl">
                            <Grid container spacing={0} className="heading-container">

                                <Grid xs={12} sm={12} md={12} lg={12} xl={8}>
                                    {/* {msg2 ? (<>
                                        <div className='msg-head'>
                                            <div className='msg-artist-name-main'>
                                                <div className='msg-avat-content'>
                                                    <Avatar alt="Remy Sharp" src={profileData.profilePicture ? profileData.profilePicture : ava} sx={{ width: 56, height: 56 }} />
                                                </div>

                                                <div className='name-msg'>
                                                    {String(roomdetails?.requestor1) === ids ? roomdetails?.receiver_name : roomdetails?.creator_name}

                                                    <div className='msg-username'>
                                                        @{roomdetails?.room_name}
                                                    </div>
                                                </div>
                                            </div>
                                            <MoreHorizIcon onClick={handleClick} />

                                            <Popover
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                className='playlist-popover'
                                            >
                                                <div className='playlist-more-div' >Messages</div>
                                                <div className='playlist-more-div' >Pay & Earn</div>
                                                <div className='playlist-more-div' >Artist Dashboard</div>
                                                <div className='playlist-more-div' >Rewards</div>
                                                <div className='playlist-more-div' >Settings</div>
                                            </Popover>
                                        </div>
                                    </>) : null} */}




                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                    <Container maxWidth="xl">
                        <Grid container spacing={0} sx={{ justifyContent: 'center' }} >
                            <Grid xs={11} sm={11} md={10} lg={8} xl={8}>
                                {msg2 ? (<>

                                    <div sx={{
                                        display: { xs: 'block', sm: 'none' },
                                    }}>
                                        {drawer}
                                    </div>

                                </>)
                                    : (<>

                                        <Grid xs={12} sm={12} md={12} lg={12} xl={12} className='start-a-convo-grid'>
                                            <div className='start-a-convo'>
                                                Start a Conversation!
                                                <div className='connect-with-auids'>
                                                    Tranfer {location?.state?.amount ? location?.state?.amount : ""} Dster Token to Connect with this Artist by starting a private direct message!
                                                </div>
                                                <Button className='write-msg-btn' onClick={() => { createRoom() }}>Make a Connection</Button>
                                            </div>
                                        </Grid>
                                    </>)}




                            </Grid>


                        </Grid>
                    </Container>

                </Box>

            </Box>
        </div >
    );
}

export default Message;