import React, { useState, useEffect } from 'react'
import SidebarNew from '../SidebarNew/SidebarNew'
import { Box, Grid } from '@mui/material'
import './AlbumNew.css'
import { Button, useTheme, useMediaQuery, Snackbar, Tabs, Tab, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Stack from '@mui/material/Stack';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Popover from '@mui/material/Popover';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import card from '../../img/dster-webp/card-4.webp'
import BookmarkIcon from '@mui/icons-material/Bookmark';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DynamicCut from '../PlayListNew/DynamicCut';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useParams } from "react-router-dom";
import Axios from '../../Axios';
import moment from 'moment'
import Musiccontrol from '../Music-Control/Musiccontrol';
import { ToastContainer, toast } from 'react-toastify';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Tooltip from '@mui/material/Tooltip';
import constant from '../../Constant';





const drawerWidth = 300;

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}



function AlbumNew() {

    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const mobileUp = useMediaQuery(theme.breakpoints.up('sm'));
    const tokens = localStorage.getItem("iphephile");
    const [isHovered, setIsHovered] = useState(false);
    const [isAlive, setIsAlive] = useState()

    const navigate = useNavigate()
    const location = useLocation()

    const [anchorEl2, setAnchorEl2] = useState(null);
    const [name, setName] = useState()
    const [tracks, setTracks] = useState(Number(localStorage.getItem("tracks")));
    const [values, setValues] = React.useState(true);
    const [dataId, setdataId] = useState()

    const [music, setMusic] = React.useState([]);
    const [usermusic, setUsermusic] = React.useState([]);
    const [audioDurations, setAudioDurations] = useState([]);
    const [audioplays, setAudioplays] = useState([]);
    const [isplay, setisplay] = useState();
    const [audiosource, setAudiosource] = React.useState();
    const [playAll, setPlayAll] = useState(false)
    const [songRow, setSongRow] = useState()
    const [songIndex, setSongIndex] = useState()
    const [id, setId] = useState()
    const [audioSourceArr, setAudioSourceArr] = useState()
    const [playingIndex, setPlayingIndex] = useState();
    const [component, setComponent] = useState()
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [play, setPlay] = React.useState([]);
    const [playname, setPlayname] = React.useState();
    const [song_id, setsongid] = React.useState();
    const [row, setRow] = useState()
    const [music_type, setMusic_type] = useState()
    const [songName, setSongName] = useState()
    const [value, setValue] = useState(0)
    const [type, setType] = useState()

    const [sample, setSample] = useState()

    const [users, setUsers] = useState({})

    // console.log(users, 'users');
    // console.log(audioSourceArr, 'audioSourceArr');
    const [songs, setSongs] = useState()
    const [PlaylistDetails, setPlaylistDetails] = useState()


    const [likedSongsCounts, setLikedSongsCount] = useState(0)
    const [savedSongsCount, setSavedSongsCount] = useState(0)

    // console.log(music, 'music');

    const existingAudio = localStorage.getItem("audiosource");
    const audio = JSON.parse(existingAudio)
    const isPause = localStorage.getItem('isPause')

    if (audio?.length > 0 && isPause == 'false') {
        localStorage.setItem("isPause", false);
    }

    const openSi = Boolean(anchorEl3);

    const handlePopoverClose3 = () => {
        setAnchorEl3(null);
    };

    const setAudio = (data) => {
        // console.log(data, 'setAudio');
        // localStorage.setItem("audiosource", data.audio);
        localStorage.setItem("audiosource", JSON.stringify(audioSourceArr));
        localStorage.setItem("songName", data.song_name);
        localStorage.setItem("Image", data.image);
        localStorage.setItem("current", 0);
        localStorage.setItem("tracks", 0);
        localStorage.setItem("isPause", false);
        setAudiosource(data.audio);
        localStorage.setItem("playsong", "false");
        setValues(!values);
        setisplay(true);
        setdataId(data._id)
    };

    const createMusic = async (data) => {
        try {
            if (tokens) {
                const create_data = {
                    song_id: data,
                };

                await Axios.post("/users/add_history", create_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        if (res?.data?.success) {
                            // getMusic()
                        } else {
                            toast.error(res?.data?.message);
                        }
                    })
                    .catch((err) => {
                        // console.log(err, "err")
                        toast.error(err?.response?.data?.message);
                    });
            }
            else {
                console.log('please login');

            }
        } catch (error) {
            // console.log(error, "error");
        }
    };

    const params = useParams();

    // console.log(params.id, 'id');


    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose3 = () => {
        setAnchorEl2(null);
    };

    const handlePopoverOpen3 = (event) => {
        setAnchorEl3(event.currentTarget);
    };

    const open2 = Boolean(anchorEl2);
    const id2 = open2 ? 'simple-popover' : undefined;

    const [playlists, setPlaylists] = useState([
        { id: 1, name: 'Playlist #1', creator: 'First Name' }
    ]);

    // Function to add a new playlist
    const addPlaylist = () => {
        const newPlaylist = {
            id: playlists.length + 1, // Simple ID generation
            name: `Playlist #${playlists.length + 1}`,
            creator: 'New Creator' // You can modify this to take user input
        };
        setPlaylists([...playlists, newPlaylist]);
        // handleClose()
    };



    const [folder, setFolder] = useState([
        { id: 1, name: 'Playlist #1', creator: 'First Name' }
    ]);

    // Function to add a new playlist
    const addFolder = () => {
        const newFolder = {
            id: folder.length + 1, // Simple ID generation
            name: `Playlist #${folder.length + 1}`,
            creator: 'New Creator' // You can modify this to take user input
        };
        setFolder([...folder, newFolder]);
        // handleClose()
    };


    const [inFolder, setInFolder,] = useState(false)

    const handleInFolder = () => {
        setInFolder(!inFolder)

    }


    const [model, setModel] = useState(false);
    const handleOpen = () => setModel(true);
    const handleClose2 = () => setModel(false);

    const [imageUrl, setImageUrl] = useState(null);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        // console.log(reader, "result of file");

        reader.onloadend = () => {
            setImageUrl(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleRightClick = (event) => {
        event.preventDefault();
        // Your function logic here
        setAnchorEl2(event.currentTarget);
        // console.log('Right click event triggered');
    };

    const { enqueueSnackbar } = useSnackbar();



    const handleClickVariant = (variant) => () => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar('This feature is under development!', { variant });
    };

    const addSave = async (id) => {
        try {
            if (tokens) {
                const payload = {
                    id: id
                }
                const response = await Axios.post('/users/addtosave', payload, {
                    headers: {
                        Authorization: tokens
                    }
                })
                if (response.data.success) {
                    // getMusic()
                    getOneSong()
                    getUserSavedCount()
                }
            }
            else {
                toast.error("Please Login To Add this Song to Save List")
                setTimeout(() => {
                    navigate('/login')
                }, 2000)
            }
        } catch (error) {
            console.log(error, 'err');

        }
    }

    const addalbumsave = async (id) => {
        try {
            if (tokens) {
                const payload = {
                    id: id
                }
                const response = await Axios.post('/users/addalbumtosave', payload, {
                    headers: {
                        Authorization: tokens
                    }
                })
                if (response.data.success) {
                    // getMusic()
                    getOneSong()
                    getUserSavedCount()
                }
            }
            else {
                toast.error("Please Login To Add this Album to Save List")
                setTimeout(() => {
                    navigate('/login')
                }, 2000)
            }
        } catch (error) {
            console.log(error, 'err');

        }
    }

    const addplaysave = async (id) => {
        try {
            if (tokens) {
                const payload = {
                    id: id
                }
                const response = await Axios.post('/users/AddPlaylistToSave', payload, {
                    headers: {
                        Authorization: tokens
                    }
                })
                if (response.data.success) {
                    // getMusic()
                    getOneSong()
                    getUserSavedCount()
                }
            }
            else {
                toast.error("Please Login To Add this Playlist to Save List")
                setTimeout(() => {
                    navigate('/login')
                }, 2000)
            }
        } catch (error) {
            console.log(error, 'err');

        }
    }

    const getaudioPlays = async (audioSrc, index) => {
        try {
            if (audioSrc === localStorage.getItem("songName")) {
                setAudioplays((prev) => ({
                    ...prev,
                    [index]: 1,
                }));
            } else {
                setAudioplays((prev) => ({
                    ...prev,
                    [index]: 0,
                }));
            }
        } catch (error) {
            console.error(error);
            setAudioplays((prev) => ({
                ...prev,
                [index]: 0,
            }));
        }
    };


    const handlePlayPause = (index) => {
        setPlayingIndex(index)
        setSongIndex(index)
    }

    const sampleIndex = () => {

    }

    const getAudioDuration = async (audioSrc) => {
        try {
            const audio = new Audio(audioSrc);
            await audio.addEventListener("loadedmetadata", () => {
                const duration = audio.duration;
                const durationInSeconds = audio.duration;
                const durationInMinutes = (durationInSeconds / 60).toFixed(2);
                setAudioDurations((prev) => ({
                    ...prev,
                    [audioSrc]: durationInMinutes,
                }));
            });
        } catch (error) {
            setAudioDurations((prev) => ({
                ...prev,
                [audioSrc]: 0, // Default duration or handle error accordingly
            }));
        }
    };



    const getplayLists = async () => {
        try {
            const datas = await Axios.post(
                "/users/get_my_playlist",
                {
                    playname: playname,
                },
                {
                    headers: {
                        Authorization: tokens,
                    },
                }
            )
                .then((res) => {
                    // console.log(res?.data?.result?.musicList,"res")
                    if (res?.data?.success) {
                        setPlay(res?.data?.result);
                    } else {
                        // setMusics([])
                        // setAlbum([])
                        setPlay([]);
                    }
                })
                .catch((err) => {
                    // console.log(err,"err")
                });
        } catch (error) {
            // console.log(error,"error");
        }
    };

    const handleAdd = async (data) => {
        try {
            const create_data = {
                playlistId: data,
                songId: song_id,
            };
            await Axios.post("/users/edit_playlist", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        toast.success(res?.data?.message);
                        handlePopoverClose3();
                        handleClose3();
                        // setTimeout(() => {
                        //     navigate('/playlistnew')
                        // }, 2000)
                    } else {
                        toast.error(res?.data?.message);
                        handlePopoverClose3();
                        handleClose3();
                    }
                })
                .catch((err) => {
                    console.log(err, "err");
                    toast.error(err?.response?.data?.message);
                });
        } catch (error) {
            console.log(error, "error");
        }
    };

    useEffect(() => {
        if (tokens) {
            getplayLists();
        }
    }, [playname]);

    useEffect(() => {
        if (usermusic?.length > 0) {
            usermusic.forEach((row) => {
                getAudioDuration(row?.audio);
            });
        }

        if (usermusic) {
            usermusic.forEach((row, index) => {
                getaudioPlays(row?.song_name, index);
            });
        }
    }, [usermusic, values]);

    useEffect(() => {
        if (music) {
            getAudioDuration(music?.audio);
        }
    }, [music]);

    useEffect(() => {
        setTracks(tracks !== undefined ? tracks : Number(localStorage.getItem("tracks")))
        // setComponent('albumnew')
        getOneSong()
    }, [name, users]);

    // useEffect(() => {
    //     getMusic();
    // }, [params._id]);

    useEffect(() => {
        if (usermusic?.length > 0 && playAll) {
            if (songRow !== undefined) {
                getAudioDuration(songRow?.audio);
            }
            else {
                getAudioDuration(usermusic[0]?.audio);
            }
        }

        if (usermusic && playAll) {
            if (songRow !== undefined) {
                getaudioPlays(songRow?.song_name, 0);
            }
            else {
                getaudioPlays(usermusic[0]?.song_name, 0);
            }
        }
    }, [usermusic, values, playAll]);


    // useEffect(() => {
    //     if (location?.state?.row) {
    //         setMusic(location?.state?.row)
    //         if (location?.state?.row?.music_type == 'track') {
    //             getOneTrack(location?.state?.row?._id)
    //         }
    //         else if (location?.state?.row?.music_type == 'album') {
    //             getOneAlbum(location?.state?.row?.album_id)
    //         }
    //         else if (location?.state?.row?.music_type == 'playlist') {
    //             getplayLists(location?.state?.row?.play_id)
    //         }
    //     }
    // }, [location?.state?.row])

    // console.log(location?.state?.row, 'location.state');

    const getOneSong = async (id) => {
        const payload = {
            song_id: params.id ? params.id : id
        }
        // console.log(payload, 'payload');

        try {
            if (tokens) {
                const response = await Axios.post('/users/getOneSong', payload, {
                    headers: {
                        Authorization: tokens
                    }
                })
                // console.log(response, 'getOneSong');
                if (response?.data?.success) {

                    setMusic_type(response?.data?.result?.[0]?.music_type)
                    setMusic(response?.data?.result?.[0])
                    if (response?.data?.result?.[0]?.music_type == 'track') {

                        // setMusic(response?.data?.result?.[0])
                        setSongName(response?.data?.result?.[0]?.song_name)
                        setUsermusic(response?.data?.result)
                        // const audioArray = response?.data?.result?.map(item => ({
                        //     _id: item?._id,
                        //     audio: item?.audio,
                        //     song_name: item?.song_name,
                        //     name: item?.createUser?.name,
                        //     userId: item?.createUser._id,
                        //     image: item?.image,
                        //     repeat: item?.repeat,
                        //     shuffle: item?.shuffle,
                        //     fav: item?.userFav
                        // }))
                        const audioArray = response?.data?.result?.map(item => ({
                            _id: item?._id,
                            audio: item?.audio,
                            component: 'albumnew'
                        }))
                        setAudioSourceArr(audioArray)
                    }
                    else if (response?.data?.result?.[0]?.music_type == 'album') {

                        // setMusic(response?.data?.result?.[0].AlbumDetails)
                        setSongName(response?.data?.result?.[0]?.album_name)
                        setUsermusic(response?.data?.result?.[0]?.AlbumSongDetails)
                        // const audioArray = response?.data?.result?.[0]?.AlbumSongDetails?.map(item => ({
                        //     _id: item?._id,
                        //     audio: item?.audio,
                        //     song_name: item?.song_name,
                        //     name: users?.name,
                        //     userId: users?._id,
                        //     image: item?.image,
                        //     repeat: item?.repeat,
                        //     shuffle: item?.shuffle,
                        //     fav: item?.userFav
                        // }))
                        const audioArray = response?.data?.result?.[0]?.AlbumSongDetails?.map(item => ({
                            _id: item?._id,
                            audio: item?.audio,
                            component: 'albumnew'
                        }))
                        setAudioSourceArr(audioArray)
                    }
                    else if (response?.data?.result?.[0]?.music_type == 'playlist') {
                        // console.log(response?.data?.result?.[0]?.PlaylistSongDetails, 'list');

                        // setMusic(response?.data?.result?.[0].PlayListDetails)
                        setSongName(response?.data?.result?.[0]?.playlist)
                        setUsermusic(response?.data?.result?.[0]?.PlaylistSongDetails)
                        // const audioArray = response?.data?.result?.[0]?.PlaylistSongDetails?.map(item => ({
                        //     _id: item?._id,
                        //     audio: item?.audio,
                        //     song_name: item?.song_name,
                        //     name: users?.name,
                        //     userId: users?._id,
                        //     image: item?.image,
                        //     repeat: item?.repeat,
                        //     shuffle: item?.shuffle,
                        //     fav: item?.userFav
                        // }))
                        const audioArray = response?.data?.result?.[0]?.PlaylistSongDetails?.map(item => ({
                            _id: item?._id,
                            audio: item?.audio,
                            component: 'albumnew'
                        }))
                        setAudioSourceArr(audioArray)
                        // console.log(audioArray, 'audioArray');

                    }
                }
            }
            else {
                const response = await Axios.post('/users/getOneSongs', payload)
                // console.log(response, 'getOneSongs');
                if (response?.data?.success) {
                    setMusic_type(response?.data?.result?.[0]?.music_type)
                    setMusic(response?.data?.result?.[0])
                    if (response?.data?.result?.[0]?.music_type == 'track') {
                        // setMusic(response?.data?.result?.[0])
                        setSongName(response?.data?.result?.[0]?.song_name)
                        setUsermusic(response?.data?.result)
                        // const audioArray = response?.data?.result?.map(item => ({
                        //     _id: item?._id,
                        //     audio: item?.audio,
                        //     song_name: item?.song_name,
                        //     name: item?.createUser?.name,
                        //     userId: item?.createUser._id,
                        //     image: item?.image,
                        //     repeat: item?.repeat,
                        //     shuffle: item?.shuffle,
                        //     fav: item?.userFav
                        // }))
                        const audioArray = response?.data?.result?.map(item => ({
                            _id: item?._id,
                            audio: item?.audio,
                            component: 'albumnew'
                        }))
                        setAudioSourceArr(audioArray)
                    }
                    else if (response?.data?.result?.[0]?.music_type == 'album') {
                        // setMusic(response?.data?.result?.[0])
                        setSongName(response?.data?.result?.[0]?.album_name)
                        setUsermusic(response?.data?.result?.[0]?.AlbumSongDetails)
                        // const audioArray = response?.data?.result?.[0]?.AlbumSongDetails?.map(item => ({
                        //     _id: item?._id,
                        //     audio: item?.audio,
                        //     song_name: item?.song_name,
                        //     name: users?.name,
                        //     userId: users?._id,
                        //     image: item?.image,
                        //     repeat: item?.repeat,
                        //     shuffle: item?.shuffle,
                        //     fav: item?.userFav
                        // }))
                        const audioArray = response?.data?.result?.[0]?.AlbumSongDetails?.map(item => ({
                            _id: item?._id,
                            audio: item?.audio,
                            component: 'albumnew'
                        }))
                        setAudioSourceArr(audioArray)
                    }
                    else {
                        // setMusic(response?.data?.result?.[0])
                        setSongName(response?.data?.result?.[0]?.playlist)
                        setUsermusic(response?.data?.result?.[0]?.PlaylistSongDetails)
                        // const audioArray = response?.data?.result?.[0]?.PlaylistSongDetails?.map(item => ({
                        //     _id: item?._id,
                        //     audio: item?.audio,
                        //     song_name: item?.song_name,
                        //     name: users?.name,
                        //     userId: users?._id,
                        //     image: item?.image,
                        //     repeat: item?.repeat,
                        //     shuffle: item?.shuffle,
                        //     fav: item?.userFav
                        // }))
                        const audioArray = response?.data?.result?.[0]?.PlaylistSongDetails?.map(item => ({
                            _id: item?._id,
                            audio: item?.audio,
                            component: 'albumnew'
                        }))
                        setAudioSourceArr(audioArray)
                    }


                }
            }

        } catch (error) {
            console.log(error, 'err');

        }
    }

    // console.log(music, 'music');

    const addLibrary = async (data) => {
        try {
            if (tokens) {
                const create_data = {
                    song_id: data
                }
                // console.log(create_data)

                await Axios.post("/users/add_library", create_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        // console.log(res, "res")
                        if (res?.data?.success) {
                            toast.success(res?.data?.message)
                            setIsAlive(false)
                            setType('track')
                            getOneSong()
                        }
                        else {
                            toast.error(res?.data?.message)

                        }
                    }).catch((err) => {
                        console.log(err, "err")
                        toast.error(err?.response?.data?.message)

                    })
            }
            else {
                toast.error('Please Login To Add Songs to Your Library')
                setTimeout(() => {
                    navigate('/login')
                }, 2000)
            }

        } catch (error) {
            console.log(error, "error");
        }

    }


    const addalbumLibrary = async (data) => {

        try {
            if (tokens) {
                const create_data = {
                    album_id: data
                }
                // console.log(create_data)

                await Axios.post("/users/add_album_to_library", create_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        // console.log(res, "res")
                        if (res?.data?.success) {
                            toast.success(res?.data?.message)
                            setIsAlive(false)
                            getOneSong()
                            setValue(2)
                            setType('album')
                            // getOneAlbum()
                            // console.log(res?.data?.message)
                        }
                        else {
                            toast.error(res?.data?.message)
                            setIsAlive(false)
                        }
                    }).catch((err) => {
                        console.log(err, "err")
                        toast.error(err?.response?.data?.message)

                    })
            }
            else {
                toast.error('Please Login To Add Songs to Your Library')
                setTimeout(() => {
                    navigate('/login')
                }, 2000)
            }

        } catch (error) {
            console.log(error, "error");
        }

    }

    // to add playlist to library
    const addplayLibrary = async (data) => {
        try {
            if (tokens) {
                const create_data = {
                    play_id: data,
                };

                await Axios.post("/users/add_playlist_to_library", create_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        if (res?.data?.success) {
                            toast.success(res?.data?.message);
                            setIsAlive(false)
                            getOneSong()
                            setValue(1)
                            setType('playlist')
                            // getplayLists();
                        } else {
                            toast.error(res?.data?.message);
                        }
                    })
                    .catch((err) => {
                        // console.log(err, "err")
                        toast.error(err?.response?.data?.message);
                    });
            }
            else {
                toast.error('Please Login To Add Songs to Your Library')
                setTimeout(() => {
                    navigate('/login')
                }, 2000)
            }
        } catch (error) {
            // console.log(error, "error");
        }
    };

    function convertToInternationalCurrencySystem(labelValue) {

        // Nine Zeroes for Billions
        return Math.abs(Number(labelValue)) >= 1.0e+9

            ? (Math.abs(Number(labelValue)) / 1.0e+9) + "B"
            // Six Zeroes for Millions 
            : Math.abs(Number(labelValue)) >= 1.0e+6

                ? (Math.abs(Number(labelValue)) / 1.0e+6) + "M"
                // Three Zeroes for Thousands
                : Math.abs(Number(labelValue)) >= 1.0e+3

                    ? (Math.abs(Number(labelValue)) / 1.0e+3) + "K"

                    : Math.abs(Number(labelValue));
    }

    // to favourite/unfavorite a track
    const addFavorite = async (data) => {
        try {
            if (tokens) {
                const create_data = {
                    song_id: data,
                };

                await Axios.post("/users/add_to_favourite", create_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        if (res?.data?.success) {
                            getOneSong()
                            getUserFavCount()
                        } else {
                            toast.error(res?.data?.message);
                        }
                    })
                    .catch((err) => {
                        // console.log(err, "err")
                        toast.error(err?.response?.data?.message);
                    });
            }
            else {
                toast.error("Please Login to add this Song to your Favourite")
                setTimeout(() => {
                    navigate('/login')
                }, 2000)
            }
        } catch (error) {
            // console.log(error, "error");
        }
    };

    // to favourite/unfavorite a album
    const addalbumFavorite = async (data) => {
        try {
            if (tokens) {
                const create_data = {
                    album_id: data
                }
                // console.log(create_data)

                await Axios.post("/users/add_fav_album", create_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        // console.log(res, "res")
                        if (res?.data?.success) {
                            toast.success(res?.data?.message)
                            getOneSong()
                            getUserFavCount()
                        }
                        else {
                            toast.error(res?.data?.message)

                        }
                    }).catch((err) => {
                        // console.log(err, "err")
                        toast.error(err?.response?.data?.message)

                    })
            }
            else {
                toast.error("Please Login to add this Album to your Favourite")
                setTimeout(() => {
                    navigate('/login')
                }, 2000)
            }
        } catch (error) {
            console.log(error, "error");
        }

    }

    // to favourite/unfavorite a Playlist
    const addplayFavorite = async (data) => {
        try {
            if (tokens) {
                const create_data = {
                    album_id: data,
                };

                await Axios.post("/users/add_fav_playlist", create_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        if (res?.data?.success) {
                            toast.success(res?.data?.message);
                            getOneSong()
                        } else {
                            toast.error(res?.data?.message);
                        }
                    })
                    .catch((err) => {
                        // console.log(err, "err")
                        toast.error(err?.response?.data?.message);
                    });
            }
            else {
                toast.error("Please Login to add this Playlist to your Favourite")
                setTimeout(() => {
                    navigate('/login')
                }, 2000)
            }
        } catch (error) {
            // console.log(error, "error");
        }
    };




    useEffect(() => {

        localStorage.setItem("songName", music.song_name)
        if (tokens != null) {
            const message = `${location.pathname}`
            localStorage.setItem("URL", message)
        }

        if (music.song_name != undefined) {
            document.title = music.song_name
        }
        setMetaTag('name', 'description', music.song_name)
        setMetaTag('name', 'keyword', "dreammusic")
        setMetaTag('property', 'og:title', music.song_name || "ShareTrack")
        setMetaTag('property', 'og:description', music.description || "ShareTrack")
        setMetaTag('property', 'og:description', music.description || "ShareTrack")
        setMetaTag('property', 'og:image', music.image)
        setMetaTag('property', 'og:url ', `${constant.MainUrl}/album/${music._id}` || window.location.href)
        return () => {
            document.title = "Music NFT";
        }

    }, [music])

    const setMetaTag = (attr, key, content) => {
        // console.log(attr, key, content, 'meta data');

        if (content) {

            let element = document.querySelector(`meta[${attr}="${key}"]`)
            // console.log(element, 'element');

            if (!element) {
                element = document.createElement('meta')
                element.setAttribute(attr, key)
                document.head.appendChild(element)
            }
            element.setAttribute('content', content)
        }
    }


    //remove song from playlist 

    const removesong = async (id) => {
        try {
            if (tokens) {
                const payload = {
                    id: id
                }
                const response = await Axios.post('/users/removesong', payload, {
                    headers: {
                        Authorization: tokens
                    }
                })
                if (response?.data?.success) {
                    toast.success(response?.data?.message)
                    getOneSong()
                }
                else {
                    toast.error(response?.data?.message)
                }
            }
            else {
                toast.error("Login to continue")
                setTimeout(() => {
                    navigate('/login')
                }, 2000)
            }
        } catch (error) {
            console.log(error, 'err');

        }
    }

    const getUserFavCount = async () => {
        try {
            const find_data = {
                explore: "My Favourites",
                name: name,
            };
            if (tokens) {
                await Axios.post("/users/getUserFavourite", find_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        if (res?.data?.success) {
                            setLikedSongsCount(res?.data?.result?.length)
                        } else {
                            setLikedSongsCount(0);
                        }
                    })
                    .catch((err) => {
                        if (err?.response?.data?.message == "Admin Blocked You") {
                            localStorage.clear();
                            navigate('/');
                            toast.error('Admin Blocked You');
                        }
                    });
            }
            // else {
            //     await Axios.post("/users/get_user_favourites", find_data)
            //         .then((res) => {
            //             if (res?.data?.success) {
            //                 setLikedSongsCount(res?.data?.result?.length)
            //             } else {
            //                 setLikedSongsCount(0)
            //             }
            //         })
            //         .catch((err) => {
            //             if (err?.response?.data?.message == "Admin Blocked You") {
            //                 localStorage.clear();
            //                 navigate('/');
            //                 toast.error('Admin Blocked You');
            //             }
            //         });
            // }

        } catch (error) {
            // console.log(error, "error");
        }
    };


    const getUserSavedCount = async () => {
        try {
            const find_data = {
                explore: "Saved Songs",
                name: name,
            };
            if (tokens) {
                await Axios.post("/users/getUserFavourite", find_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        if (res?.data?.success) {
                            setSavedSongsCount(res?.data?.result?.length)
                        } else {
                            setSavedSongsCount(0);
                        }
                    })
                    .catch((err) => {
                        if (err?.response?.data?.message == "Admin Blocked You") {
                            localStorage.clear();
                            navigate('/');
                            toast.error('Admin Blocked You');
                        }
                    });
            }
            // else {
            //     await Axios.post("/users/get_user_favourites", find_data)
            //         .then((res) => {
            //             if (res?.data?.success) {
            //                 setSavedSongsCount(res?.data?.result?.length)
            //             } else {
            //                 setSavedSongsCount(0);
            //             }
            //         })
            //         .catch((err) => {
            //             if (err?.response?.data?.message == "Admin Blocked You") {
            //                 localStorage.clear();
            //                 navigate('/');
            //                 toast.error('Admin Blocked You');
            //             }
            //         });
            // }

        } catch (error) {
            // console.log(error, "error");
        }
    };

    useEffect(() => {
        getUserFavCount()
        getUserSavedCount()
    }, [likedSongsCounts, savedSongsCount])

    const saveFunction = async (music) => {
        if (music_type == 'track') {
            addSave(music._id)
        }
        else if (music_type == 'album') {
            addalbumsave(music.album_id)
        }
        else if (music_type == 'playlist') {
            addplaysave(music.play_id)
        }
    }

    const getArtistProfile = async (id) => {
        try {
            const payload = {
                userId: id
            }
            const response = await Axios.post('/users/getArtistProfile', payload, {
                headers: {
                    Authorization: tokens
                }
            })
            if (response.data.success) {
                setUsers(response.data.result)
            }
            else {
                setUsers({})
            }
        } catch (error) {
            console.log(error, 'err');

        }
    }




    const getMusicLibrary = async () => {

    }

    const getUserFav = async () => {

    }
    return (
        <>
            <ToastContainer />
            <div className='AlbumNew'>

                <Box sx={{ display: mobileUp ? 'flex' : 'block' }}>
                    <SidebarNew name={name} setName={setName} />
                    <Box
                        component="main"
                        // className="explore-box"
                        sx={{
                            flexGrow: 1,
                            py: 1,
                            width: { sm: `calc(100% - ${drawerWidth}px)` },
                            marginTop: "80px",
                            marginBottom: "100px",
                        }}
                    >

                        <Grid container spacing={0} >
                            <Grid
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={9}
                                sx={{ padding: '15px' }}

                            >

                                <Stack direction="row" spacing={1}>
                                    <div className='arrowbck'>
                                        <ArrowBackIosNewIcon
                                            onClick={(() => { navigate(-1) })}
                                        />
                                    </div>
                                    {/* <div className='arrowbck'>
                                        <ArrowForwardIosIcon onClick={(() => { navigate(+1) })} />
                                    </div> */}

                                </Stack>
                                <div className="display-2 margin-top ablmcov" >
                                    <div className="display-1">
                                        <div className="albmc-img">
                                            <img src={music?.image && !music?.image?.includes('localhost') ? music?.image : card} alt="ad" />
                                        </div>
                                        <div className="melod">
                                            {/* {" Millenium Melodies".slice(0, 20) + '...'} */}
                                            {/* {music?.song_name !== undefined ? music?.song_name?.length > 15 ? music?.song_name?.slice(0, 15) + '...' : music?.song_name
                                                :
                                                music?.album_name ? music?.album_name?.length > 15 ? music?.album_name?.slice(0, 15) + '...' : music?.album_name
                                                    :
                                                    music?.playlist ? music?.playlist?.length > 15 ? music?.playlist?.slice(0, 15) + '...' : music?.playlist : ""
                                            } */}
                                            {songName?.length > 15 ? songName?.slice(0, 15) + '...' : songName}
                                            <div className="display-1">
                                                <div className="dser">
                                                    {/* Dreamster */} {music?.createUser?.name}
                                                </div>
                                                <div className="n-song">
                                                    {usermusic?.length} songs
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <Stack direction={!mobileUp ? "column" : "row"} sx={{ gap: !mobileUp && '15px' }} spacing={!mobileUp ? 0 : 4}>
                                        {/* <FavoriteIcon
                                            // onClick={handleClickVariant('warning')}
                                            onClick={() => { music_type == 'track' ? addFavorite(music?._id) : music_type == 'album' ? addalbumFavorite(music?.album_id) : music_type == 'playlist' ? addplayFavorite(music?.play_id) : "" }}
                                            // className={music?.userFav === true ? "fav-heart" : "normal-svg"}
                                            className={music_type == 'track' ? music?.userFav === true ? "fav-heart" : "normal-svg"
                                                : music_type == 'album' ? music.AlbumDetails.userFav == true ? "fav-heart" : "normal-svg"
                                                    : music_type == 'playlist' ? music.PlayListDetails.userFav == true ? "fav-heart" : "normal-svg" : ''
                                            }
                                        /> */}
                                        <BookmarkIcon
                                            // onClick={handleClickVariant('warning')}
                                            onClick={() => { saveFunction(music) }}
                                            // onClick={() => { music_type == 'track' ? addSave(music?._id) : music_type == 'album' ? addalbumsave(music?.album_id) : music_type == 'playlist' ? addplaysave(music?.play_id) : '' }}
                                            className={
                                                music?.userSaved === true
                                                    ? "saved-svg"
                                                    : "fav-saved"

                                            }
                                        />

                                    </Stack>
                                </div>
                                <div className="display-2 mg-top-30">
                                    <Stack direction="row" spacing={4} sx={{ alignItems: 'center' }}>
                                        <div className="albPlu cursor">
                                            {
                                                songRow != undefined ?
                                                    audioDurations[usermusic[songIndex]?.audio] !== undefined &&
                                                    <>
                                                        {/* {audioplays[songIndex] === 0 || */}
                                                        {playingIndex !== songIndex ||
                                                            isplay === false ? (
                                                            <PlayArrowIcon sx={{ fill: '#fff' }}
                                                                onClick={() => {
                                                                    setAudio(songRow);
                                                                    createMusic(songRow);
                                                                    setAudiosource(songRow);
                                                                    setPlayAll(true)
                                                                    setTracks(songIndex)
                                                                    handlePlayPause(songIndex)
                                                                    getArtistProfile(songRow?.user_id)
                                                                }} />

                                                        ) : (
                                                            <PauseIcon sx={{ fill: '#fff' }}
                                                                onClick={() => {
                                                                    setisplay(false);
                                                                }} />
                                                        )}
                                                    </> :
                                                    audioDurations[usermusic[0]?.audio] !== undefined &&
                                                    <>
                                                        {/* {audioplays[0] === 0 || */}
                                                        {playingIndex !== 0 ||
                                                            isplay === false ? (
                                                            <PlayArrowIcon sx={{ fill: '#fff' }}
                                                                onClick={() => {
                                                                    setAudio(usermusic[0]);
                                                                    createMusic(usermusic[0]?._id);
                                                                    setAudiosource(usermusic[0]?.audio);
                                                                    setPlayAll(true)
                                                                    handlePlayPause(0)
                                                                    getArtistProfile(usermusic[0]?.user_id)
                                                                }} />

                                                        ) : (
                                                            <PauseIcon sx={{ fill: '#fff' }}
                                                                onClick={() => {
                                                                    setisplay(false);
                                                                }} />
                                                        )}
                                                    </>
                                            }

                                            {/* <PlayArrowIcon sx={{ fill: '#fff' }} /> */}
                                        </div>
                                        <div className="svgsz display-1">
                                            {/* {
                                                music?.music_type == 'track' ? <></> : */}
                                            <Tooltip title={music?.userFound ? "Remove from Your Library" : "Save to Your Library"}
                                                // title='Save to Your Library'
                                                placement="top">
                                                <AddCircleOutlineIcon
                                                    // onClick={handleClickVariant('warning')} 
                                                    // onClick={() => { music?.music_type == 'track' ? addLibrary(music?._id) : location?.state?.row?.music_type == 'album' ? addalbumLibrary(id ? id : music?.album_id) : addplayLibrary(id ? id : music?.play_id) }}
                                                    onClick={() => { music?.music_type == 'track' ? addLibrary(music?._id) : music?.music_type == 'album' ? addalbumLibrary(id ? id : music?.album_id) : addplayLibrary(id ? id : music?.play_id) }}

                                                />
                                            </Tooltip>
                                            {/* } */}
                                        </div>
                                        {/* <div className="svgsz">
                                            <MoreHorizIcon onClick={handleClick2} />
                                        </div> */}
                                    </Stack>

                                    <Stack direction="row" spacing={4} sx={{ alignItems: 'center' }}>
                                        {/* <div className="frstname" style={{ fontSize: '18px' }}>
                                            List
                                        </div>
                                        <div className="svgsz">
                                            <FormatListBulletedIcon />
                                        </div> */}
                                    </Stack>
                                </div>
                                {/* <hr style={{ borderColor: '#ffffff5c' }} /> */}
                                <div className="concet-tex">
                                    Concerts
                                </div>
                                <div className="display-1 cont-mn">
                                    <div className="conet">
                                        <img src={card} alt="dad" />
                                    </div>
                                    <div className="ontour">
                                        On Tour
                                        <div className="ontour-tx">
                                            Toper 2
                                        </div>
                                        <div className="seeall">
                                            <Button>
                                                See all events
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                                <TableContainer className='tablecont-playlist '>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell>Title</TableCell>
                                                <TableCell align="center">Date Added</TableCell>
                                                <TableCell align="center">Most Listen</TableCell>
                                                <TableCell align="center">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_4416_2693)">
                                                            <path d="M11.7841 20.5331C16.6258 20.5331 20.5507 16.6082 20.5507 11.7666C20.5507 6.92493 16.6258 3 11.7841 3C6.9425 3 3.01758 6.92493 3.01758 11.7666C3.01758 16.6082 6.9425 20.5331 11.7841 20.5331Z" stroke="white" stroke-width="1.40265" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M11.6895 7.16016V12.274H16.8033" stroke="white" stroke-width="1.40265" stroke-linecap="round" stroke-linejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_4416_2693">
                                                                <rect width="23.3775" height="23.3775" fill="white" transform="translate(0 0.585938)" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>

                                                </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {/* {['', '', '', '', ''].map((row, i) => { */}
                                            {
                                                usermusic?.length > 0 ?
                                                    usermusic?.map((row, i) => {
                                                        return (
                                                            <TableRow
                                                                // key={row.name}
                                                                sx={{
                                                                    '&:last-child td, &:last-child th': { border: 0 }, borderRadius: '10px',
                                                                    overflow: 'hidden',
                                                                }}
                                                                onContextMenu={handleRightClick}
                                                                onMouseEnter={() => setIsHovered(true)}
                                                                onMouseLeave={() => setIsHovered(false)}
                                                                className='alb cursor'
                                                            >
                                                                <TableCell align="center">
                                                                    {isHovered ?
                                                                        audioDurations[row?.audio] !== undefined &&
                                                                        <>
                                                                            {/* {audioplays[i] === 0 || */}
                                                                            {playingIndex !== i ||
                                                                                isplay === false ? (
                                                                                <PlayArrowIcon className='plybt'
                                                                                    onClick={() => {
                                                                                        setAudio(row);
                                                                                        createMusic(row?._id);
                                                                                        setAudiosource(row?.audio);
                                                                                        setSongRow(row)
                                                                                        setSongIndex(i)
                                                                                        setTracks(i)
                                                                                        handlePlayPause(i)
                                                                                        getArtistProfile(row?.user_id)
                                                                                    }} />

                                                                            ) : (
                                                                                <PauseIcon
                                                                                    onClick={() => {
                                                                                        setisplay(false);
                                                                                        handlePlayPause(i)
                                                                                    }} />
                                                                            )}
                                                                        </>
                                                                        : i + 1}
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <div className="display-1">
                                                                        <div className="tb-img">
                                                                            <img src={row?.image && !row?.image?.includes('localhost') ? row?.image : card} alt="" />
                                                                        </div>
                                                                        <div className="wecant">
                                                                            {/* We can't be friends */}

                                                                            {row?.song_name?.length > 15 ? row?.song_name?.slice(0, 15) + '...' : row?.song_name}
                                                                            <div className="inge-nmae">
                                                                                {/* Singer Name */} {row?.createUser?.[0]?.name}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <div className="dtead">
                                                                        {/* 22/01/2024, at 06.30pm */} {`${moment(row?.createdAt).format('DD/MM/YYYY')}, at ${moment(row?.createdAt).format('hh.mmA').toLowerCase()}`}
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <div className="dtead">
                                                                        {/* 1M listened */} {convertToInternationalCurrencySystem(row?.plays)} listened
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <div className="dtead">
                                                                        {/* 3:39 */} {audioDurations[row?.audio] != undefined ? audioDurations[row?.audio] : 0.00}
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <div className='display-1'>
                                                                        <div className="mnt">
                                                                            <Button onClick={(() => { navigate('/mintnew') })}>
                                                                                {/* Mint */}
                                                                                Buy
                                                                            </Button>
                                                                        </div>
                                                                        <div>
                                                                            {isHovered ? <Tooltip
                                                                                title={
                                                                                    row?.userFav === true
                                                                                        ? "Remove From Favourite"
                                                                                        : "Add to Favourite"
                                                                                }
                                                                                placement="top"
                                                                            >
                                                                                <FavoriteIcon
                                                                                    onClick={() => {
                                                                                        addFavorite(row?._id);
                                                                                    }}
                                                                                    className={
                                                                                        row?.userFav === true
                                                                                            ? "fav-heart"
                                                                                            : "normal-svg"
                                                                                    }
                                                                                />
                                                                            </Tooltip> : <></>}
                                                                        </div>
                                                                        {isHovered ? <MoreVertIcon
                                                                            // onClick={handleClick2} 
                                                                            onClick={(e) => { handleClick2(e); setsongid(row._id); setRow(row) }}
                                                                        /> : null}
                                                                    </div>
                                                                    <Popover
                                                                        id={id2}
                                                                        open={open2}
                                                                        anchorEl={anchorEl2}
                                                                        onClose={handleClose3}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'left',
                                                                        }}

                                                                    >
                                                                        <div className="createplylist" style={{ padding: '10px', borderRadius: '10px' }}>
                                                                            <div className=' display-1 cursor' onClick={handlePopoverOpen3}>
                                                                                <div className=''>
                                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <g clip-path="url(#clip0_4446_2782)">
                                                                                            <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                            <path d="M10 12.5C11.7259 12.5 13.125 11.1009 13.125 9.375C13.125 7.64911 11.7259 6.25 10 6.25C8.27411 6.25 6.875 7.64911 6.875 9.375C6.875 11.1009 8.27411 12.5 10 12.5Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                            <path d="M4.98438 15.5757C5.45462 14.6494 6.17216 13.8715 7.05745 13.3281C7.94275 12.7847 8.96123 12.4971 10 12.4971C11.0388 12.4971 12.0572 12.7847 12.9425 13.3281C13.8278 13.8715 14.5454 14.6494 15.0156 15.5757" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_4446_2782">
                                                                                                <rect width="20" height="20" fill="white" />
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>
                                                                                </div>
                                                                                <div>
                                                                                    <div className='frstname' >
                                                                                        Add to Playlist
                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                            {/* {
                                                                                row?.music_type == 'playlist' ?
                                                                                    <div className=' display-1 cursor' onClick={() => { removesong(song_id) }} >
                                                                                        <div className=''>
                                                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <g clip-path="url(#clip0_4446_2782)">
                                                                                                    <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    <path d="M10 12.5C11.7259 12.5 13.125 11.1009 13.125 9.375C13.125 7.64911 11.7259 6.25 10 6.25C8.27411 6.25 6.875 7.64911 6.875 9.375C6.875 11.1009 8.27411 12.5 10 12.5Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    <path d="M4.98438 15.5757C5.45462 14.6494 6.17216 13.8715 7.05745 13.3281C7.94275 12.7847 8.96123 12.4971 10 12.4971C11.0388 12.4971 12.0572 12.7847 12.9425 13.3281C13.8278 13.8715 14.5454 14.6494 15.0156 15.5757" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                </g>
                                                                                                <defs>
                                                                                                    <clipPath id="clip0_4446_2782">
                                                                                                        <rect width="20" height="20" fill="white" />
                                                                                                    </clipPath>
                                                                                                </defs>
                                                                                            </svg>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div className='frstname'  >
                                                                                                Remove from Playlist
                                                                                            </div>

                                                                                        </div>

                                                                                    </div>
                                                                                    : <></>
                                                                            } */}

                                                                            {/* <hr style={{ borderColor: '#ffffff5c' }} />
                                                                            <div className=' display-1 cursor' >
                                                                                <div className=''>
                                                                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M5.5 15.8751H1.75C1.58424 15.8751 1.42527 15.8093 1.30806 15.692C1.19085 15.5748 1.125 15.4159 1.125 15.2501V11.7587C1.12508 11.5932 1.19082 11.4344 1.30781 11.3173L10.9422 1.68291C11.0594 1.56579 11.2183 1.5 11.384 1.5C11.5497 1.5 11.7086 1.56579 11.8258 1.68291L15.3172 5.17198C15.4343 5.28917 15.5001 5.44808 15.5001 5.61377C15.5001 5.77946 15.4343 5.93837 15.3172 6.05557L5.5 15.8751Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        <path d="M14.875 15.875H5.5" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        <path d="M8.625 4L13 8.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                    </svg>



                                                                                </div>
                                                                                <div>
                                                                                    <div className='frstname' >
                                                                                        Edit details
                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                            <div className=' display-1 cursor' >
                                                                                <div className=''>
                                                                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M14.875 3.375H1.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        <path d="M6.125 7.125V12.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        <path d="M9.875 7.125V12.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        <path d="M13.625 3.375V15.25C13.625 15.4158 13.5592 15.5747 13.4419 15.6919C13.3247 15.8092 13.1658 15.875 13 15.875H3C2.83424 15.875 2.67527 15.8092 2.55806 15.6919C2.44085 15.5747 2.375 15.4158 2.375 15.25V3.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        <path d="M11.125 3.375V2.125C11.125 1.79348 10.9933 1.47554 10.7589 1.24112C10.5245 1.0067 10.2065 0.875 9.875 0.875H6.125C5.79348 0.875 5.47554 1.0067 5.24112 1.24112C5.0067 1.47554 4.875 1.79348 4.875 2.125V3.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                    </svg>


                                                                                </div>
                                                                                <div>
                                                                                    <div className='frstname' >
                                                                                        Delete
                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                            <hr style={{ borderColor: '#ffffff5c' }} />
                                                                            <div className=' display-1 cursor' onClick={addPlaylist}>
                                                                                <div className=''>
                                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M1.66602 1.66699H18.3327V10.8337H16.666V3.33366H3.33268V16.667H10.8327V18.3337H1.66602V1.66699ZM9.99935 5.83366H13.3327V7.50033H11.666V11.667C11.6657 12.1976 11.4966 12.7144 11.1832 13.1426C10.8697 13.5707 10.4281 13.888 9.92234 14.0485C9.41657 14.2091 8.87284 14.2045 8.36984 14.0355C7.86683 13.8665 7.43064 13.5419 7.1244 13.1085C6.81815 12.6752 6.65773 12.1556 6.66635 11.6251C6.67497 11.0945 6.85218 10.5804 7.17234 10.1573C7.49251 9.73412 7.93901 9.4238 8.44724 9.27124C8.95547 9.11867 9.49906 9.13178 9.99935 9.30866V5.83366ZM9.99935 11.667C9.99935 11.446 9.91155 11.234 9.75527 11.0777C9.59899 10.9215 9.38703 10.8337 9.16602 10.8337C8.945 10.8337 8.73304 10.9215 8.57676 11.0777C8.42048 11.234 8.33268 11.446 8.33268 11.667C8.33268 11.888 8.42048 12.1 8.57676 12.2562C8.73304 12.4125 8.945 12.5003 9.16602 12.5003C9.38703 12.5003 9.59899 12.4125 9.75527 12.2562C9.91155 12.1 9.99935 11.888 9.99935 11.667ZM16.666 12.5003V15.0003H19.166V16.667H16.666V19.167H14.9993V16.667H12.4993V15.0003H14.9993V12.5003H16.666Z" fill="#636363" />
                                                                                    </svg>

                                                                                </div>
                                                                                <div>
                                                                                    <div className='frstname' >
                                                                                        Create PlayList
                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                            <div className=' display-1 cursor' onClick={addFolder} >
                                                                                <div className=''>
                                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <g clip-path="url(#clip0_4446_2802)">
                                                                                            <path d="M3.125 10H16.875" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                            <path d="M10 3.125V16.875" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_4446_2802">
                                                                                                <rect width="20" height="20" fill="white" />
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>

                                                                                </div>
                                                                                <div>
                                                                                    <div className='frstname' >
                                                                                        Create a Playlist folder
                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                            <div className=' display-1 cursor' >
                                                                                <div className=''>
                                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M10.8227 10.3539L12.6275 12.1587L12.1596 12.6266L10.3549 10.8218L10.0013 10.4682L9.64775 10.8218L7.84297 12.6266L7.37508 12.1587L9.17986 10.3539L9.53341 10.0003L9.17986 9.64677L7.37508 7.84199L7.84297 7.3741L9.64775 9.17888L10.0013 9.53243L10.3549 9.17888L12.1596 7.3741L12.6275 7.84199L10.8227 9.64677L10.4692 10.0003L10.8227 10.3539ZM2.83464 10.0003C2.83464 13.9515 6.05016 17.167 10.0013 17.167C13.9524 17.167 17.168 13.9515 17.168 10.0003C17.168 6.04918 13.9524 2.83366 10.0013 2.83366C6.05016 2.83366 2.83464 6.04918 2.83464 10.0003ZM2.16797 10.0003C2.16797 5.66813 5.66911 2.16699 10.0013 2.16699C14.3335 2.16699 17.8346 5.66813 17.8346 10.0003C17.8346 14.3325 14.3335 17.8337 10.0013 17.8337C5.66911 17.8337 2.16797 14.3325 2.16797 10.0003Z" stroke="#636363" />
                                                                                    </svg>


                                                                                </div>
                                                                                <div>
                                                                                    <div className='frstname' >
                                                                                        Exclude from your taste profile
                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                            <div className=' display-1 cursor'  >
                                                                                <div className=''>
                                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <g clip-path="url(#clip0_4446_2807)">
                                                                                            <path d="M17.5 6.875V15.6945C17.5 15.8419 17.4415 15.9831 17.3373 16.0873C17.2331 16.1915 17.0919 16.25 16.9445 16.25H3.125C2.95924 16.25 2.80027 16.1842 2.68306 16.0669C2.56585 15.9497 2.5 15.7908 2.5 15.625V5C2.5 4.83424 2.56585 4.67527 2.68306 4.55806C2.80027 4.44085 2.95924 4.375 3.125 4.375H7.29141C7.42664 4.375 7.55822 4.41886 7.66641 4.5L10 6.25H16.875C17.0408 6.25 17.1997 6.31585 17.3169 6.43306C17.4342 6.55027 17.5 6.70924 17.5 6.875Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_4446_2807">
                                                                                                <rect width="20" height="20" fill="white" />
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>
                                                                                </div>
                                                                                <div>
                                                                                    <div className='frstname' >
                                                                                        Move to folder
                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                            <div className=' display-1 cursor' >
                                                                                <div className=''>
                                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <g clip-path="url(#clip0_4446_2811)">
                                                                                            <path d="M17.9422 6.68291C18.0594 6.56571 18.1252 6.40681 18.1252 6.24112C18.1252 6.07543 18.0594 5.91652 17.9422 5.79932L13.2032 1.05791C13.086 0.940792 12.9271 0.875 12.7614 0.875C12.5957 0.875 12.4368 0.940792 12.3196 1.05791L7.84303 5.54698C7.84303 5.54698 5.67506 4.46338 3.35943 6.33213C3.29077 6.3871 3.23447 6.45593 3.19424 6.53415C3.154 6.61236 3.13072 6.69818 3.12593 6.78601C3.12114 6.87383 3.13493 6.96168 3.16642 7.04381C3.19791 7.12593 3.24637 7.20049 3.30865 7.2626L11.7383 15.6915C11.8015 15.7542 11.8773 15.8027 11.9606 15.8337C12.044 15.8647 12.133 15.8776 12.2218 15.8715C12.3105 15.8653 12.3969 15.8403 12.4752 15.7981C12.5535 15.7559 12.6219 15.6974 12.6758 15.6267C13.3313 14.7548 14.361 12.9634 13.4657 11.1735L17.9422 6.68291Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                            <path d="M7.52266 11.4775L3.75 15.2502" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_4446_2811">
                                                                                                <rect width="20" height="20" fill="white" />
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>

                                                                                </div>
                                                                                <div>
                                                                                    <div className='frstname' >
                                                                                        Pin playlist
                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                            <hr style={{ borderColor: '#ffffff5c' }} />
                                                                            <div className=' display-1 cursor' >
                                                                                <div className=''>
                                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <g clip-path="url(#clip0_4446_2816)">
                                                                                            <path d="M2.41876 14.525C3.70469 13.1555 7.08048 10.25 11.8773 10.25V14L18.1273 7.75L11.8773 1.5V5.25C7.75235 5.25 2.46407 9.19141 1.87735 14.2766C1.86923 14.3424 1.88228 14.4091 1.9146 14.4671C1.94693 14.525 1.99686 14.5712 2.05717 14.5988C2.11747 14.6265 2.18502 14.6343 2.25003 14.621C2.31504 14.6077 2.37413 14.5741 2.41876 14.525Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_4446_2816">
                                                                                                <rect width="20" height="20" fill="white" />
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>


                                                                                </div>
                                                                                <div>
                                                                                    <div className='frstname' >
                                                                                        Share
                                                                                    </div>

                                                                                </div>

                                                                            </div> */}
                                                                        </div>
                                                                    </Popover>
                                                                    <Popover
                                                                        id="mouse-over-popover"

                                                                        open={openSi}
                                                                        anchorEl={anchorEl3}
                                                                        anchorOrigin={{
                                                                            vertical: 'center',
                                                                            horizontal: 'left',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        onClose={handlePopoverClose3}
                                                                        disableRestoreFocus
                                                                        className='siPopover'
                                                                    >
                                                                        <div className="createplylist" style={{ padding: '10px', borderRadius: '10px' }}>


                                                                            <div className=' display-1 cursor' >
                                                                                <div className=''>
                                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <g clip-path="url(#clip0_4446_2802)">
                                                                                            <path d="M3.125 10H16.875" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                            <path d="M10 3.125V16.875" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_4446_2802">
                                                                                                <rect width="20" height="20" fill="white" />
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>

                                                                                </div>
                                                                                {/* <NavLink to='/playlistnew' state={song_id}> */}
                                                                                <div className='frstname cursor' onClick={() => {
                                                                                    if (tokens) {
                                                                                        navigate('/playlist', { state: { song_id: song_id, value: "recent" } })
                                                                                    }
                                                                                    else {
                                                                                        toast.error('Login to Continue')
                                                                                        handlePopoverClose3()
                                                                                        handleClose3()
                                                                                        setTimeout(() => {
                                                                                            navigate('/login')
                                                                                        }, [2000])
                                                                                    }
                                                                                }}>
                                                                                    New Playlist
                                                                                </div>
                                                                                {/* </NavLink> */}

                                                                            </div>

                                                                            <hr style={{ borderColor: '#ffffff5c' }} />
                                                                            {
                                                                                play.length > 0 ?
                                                                                    play?.map((row, ind) => (
                                                                                        <div className=' display-1 cursor' onClick={() => {
                                                                                            handleAdd(row?._id);
                                                                                        }}>
                                                                                            <div className=''>
                                                                                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M5.5 15.8751H1.75C1.58424 15.8751 1.42527 15.8093 1.30806 15.692C1.19085 15.5748 1.125 15.4159 1.125 15.2501V11.7587C1.12508 11.5932 1.19082 11.4344 1.30781 11.3173L10.9422 1.68291C11.0594 1.56579 11.2183 1.5 11.384 1.5C11.5497 1.5 11.7086 1.56579 11.8258 1.68291L15.3172 5.17198C15.4343 5.28917 15.5001 5.44808 15.5001 5.61377C15.5001 5.77946 15.4343 5.93837 15.3172 6.05557L5.5 15.8751Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    <path d="M14.875 15.875H5.5" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    <path d="M8.625 4L13 8.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                </svg>
                                                                                                {/* <img src={row?.image} /> */}
                                                                                            </div>
                                                                                            <div>
                                                                                                <div className='frstname'  >
                                                                                                    {row?.playlist}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )) :
                                                                                    <><p>You Don't have playlists</p></>
                                                                            }

                                                                            {/* <div className=' display-1 cursor' >
                                                                                <div className=''>
                                                                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M14.875 3.375H1.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        <path d="M6.125 7.125V12.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        <path d="M9.875 7.125V12.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        <path d="M13.625 3.375V15.25C13.625 15.4158 13.5592 15.5747 13.4419 15.6919C13.3247 15.8092 13.1658 15.875 13 15.875H3C2.83424 15.875 2.67527 15.8092 2.55806 15.6919C2.44085 15.5747 2.375 15.4158 2.375 15.25V3.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        <path d="M11.125 3.375V2.125C11.125 1.79348 10.9933 1.47554 10.7589 1.24112C10.5245 1.0067 10.2065 0.875 9.875 0.875H6.125C5.79348 0.875 5.47554 1.0067 5.24112 1.24112C5.0067 1.47554 4.875 1.79348 4.875 2.125V3.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                    </svg>


                                                                                </div>
                                                                                <div>
                                                                                    <div className='frstname' >
                                                                                        Delete
                                                                                    </div>

                                                                                </div>

                                                                            </div> */}
                                                                            <hr style={{ borderColor: '#ffffff5c' }} />
                                                                        </div>
                                                                    </Popover>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }) :
                                                    <div><p style={{ color: 'white' }}>No Data Found</p></div>
                                            }

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid xs={12} sx={12} md={12} lg={12} xl={3}>
                                <DynamicCut
                                    isAlive={isAlive}
                                    setIsAlive={setIsAlive}
                                    tabValue={value}
                                    setTabValue={setValue}
                                    likedSongsCounts={likedSongsCounts}
                                    savedSongsCount={savedSongsCount}
                                    setType={setType}
                                    type={type}
                                    setSongs={setSongs}
                                    setPlaylistDetails={setPlaylistDetails}
                                    getMusicLibrary={getMusicLibrary}
                                    getUserFav={getUserFav}
                                    getOneSong={getOneSong}
                                />
                            </Grid>
                        </Grid>

                        {/* <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}

                    >
                        <div className="createplylist" style={{ padding: '10px', borderRadius: '10px' }}>
                            <div className=' display-1 cursor' onClick={addPlaylist}>
                                <div className=''>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.66602 1.66699H18.3327V10.8337H16.666V3.33366H3.33268V16.667H10.8327V18.3337H1.66602V1.66699ZM9.99935 5.83366H13.3327V7.50033H11.666V11.667C11.6657 12.1976 11.4966 12.7144 11.1832 13.1426C10.8697 13.5707 10.4281 13.888 9.92234 14.0485C9.41657 14.2091 8.87284 14.2045 8.36984 14.0355C7.86683 13.8665 7.43064 13.5419 7.1244 13.1085C6.81815 12.6752 6.65773 12.1556 6.66635 11.6251C6.67497 11.0945 6.85218 10.5804 7.17234 10.1573C7.49251 9.73412 7.93901 9.4238 8.44724 9.27124C8.95547 9.11867 9.49906 9.13178 9.99935 9.30866V5.83366ZM9.99935 11.667C9.99935 11.446 9.91155 11.234 9.75527 11.0777C9.59899 10.9215 9.38703 10.8337 9.16602 10.8337C8.945 10.8337 8.73304 10.9215 8.57676 11.0777C8.42048 11.234 8.33268 11.446 8.33268 11.667C8.33268 11.888 8.42048 12.1 8.57676 12.2562C8.73304 12.4125 8.945 12.5003 9.16602 12.5003C9.38703 12.5003 9.59899 12.4125 9.75527 12.2562C9.91155 12.1 9.99935 11.888 9.99935 11.667ZM16.666 12.5003V15.0003H19.166V16.667H16.666V19.167H14.9993V16.667H12.4993V15.0003H14.9993V12.5003H16.666Z" fill="#636363" />
                                    </svg>

                                </div>
                                <div>
                                    <div className='frstname' >
                                        Create PlayList
                                    </div>

                                </div>

                            </div>
                            <div className=' display-1 cursor' onClick={addFolder} >
                                <div className=''>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.64102 4.99967L9.30768 6.66634H16.666V14.9997H3.33268V4.99967H7.64102ZM8.33268 3.33301H3.33268C2.41602 3.33301 1.67435 4.08301 1.67435 4.99967L1.66602 14.9997C1.66602 15.9163 2.41602 16.6663 3.33268 16.6663H16.666C17.5827 16.6663 18.3327 15.9163 18.3327 14.9997V6.66634C18.3327 5.74967 17.5827 4.99967 16.666 4.99967H9.99935L8.33268 3.33301Z" fill="#636363" />
                                    </svg>
                                </div>
                                <div>
                                    <div className='frstname' >
                                        Create a playlist folder
                                    </div>

                                </div>

                            </div>
                        </div>
                    </Popover> */}

                        {/* <Modal
                        open={model}
                        // onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className="modal-style"
                    >
                        <Box sx={style}>
                            <div className="display-2">
                                <div className="edtdet">
                                    Edit details
                                </div>
                                <div className="cls" onClick={handleClose2}>
                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.0625 0.9375L0.9375 9.0625M0.9375 0.9375L9.0625 9.0625" stroke="#4C4C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>

                            </div>
                            <div className="margin-top display-1">

                                <Button
                                    className='lbk'
                                    component="label"
                                    role={undefined}
                                    // variant="contained"
                                    tabIndex={-1}
                                // startIcon={<CloudUploadIcon />}
                                >
                                    <div className="palylistimg cursor">
                                        <img src={imageUrl ? imageUrl : playlist} alt="playlist" />
                                    </div>
                                    <VisuallyHiddenInput type="file" onChange={handleImageUpload} />
                                    <div className='chsimg'>
                                        Choose Image
                                    </div>
                                </Button>
                                <div className="mdoel-inpu">
                                    <TextField id="outlined-basic" placeholder="Name" variant="outlined" />
                                    <div className="mdoel-text">
                                        <TextField
                                            id="outlined-multiline-static"
                                            placeholder="Description"
                                            multiline
                                            rows={4}
                                        // defaultValue="Default Value"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="sve">
                                <Button>
                                    Save
                                </Button>
                            </div>
                            <div className="by-precee" style={{ color: 'var(--clr-font)' }}>
                                By proceeding, you agree to give Dreamster access to the image you choose to Upload Please make sure you have the right to upload the image.
                            </div>
                        </Box>
                    </Modal> */}
                    </Box>
                </Box>


                {(localStorage.getItem("playsong") === "false" && audiosource) ? (
                    <Musiccontrol
                        // audiosource={audiosource}
                        audiosource={audioSourceArr}
                        tracks={tracks}
                        isplay={sample}
                        setisplay={setSample}
                        Play={isplay}
                        setPlay={setisplay}
                        data={dataId}
                        getOneSong={getOneSong}
                        handlePlay={sampleIndex}
                        handlePlay1={handlePlayPause}
                        component={component}
                        getUserFav={getUserFavCount}
                    />
                ) : (
                    <>
                        {(localStorage.getItem("playsong") === "false" && audio !== null) ? (
                            <Musiccontrol
                                audiosource={audio}
                                tracks={tracks}
                                isplay={sample}
                                setisplay={setSample}
                                Play={isplay}
                                setPlay={setisplay}
                                data={dataId}
                                getOneSong={getOneSong}
                                handlePlay={sampleIndex}
                                handlePlay1={handlePlayPause}
                                component={component}
                                getUserFav={getUserFavCount}

                            />
                        ) : (
                            <></>
                        )}{" "}
                    </>
                )}


            </div >
        </>
    )
}

export default AlbumNew
